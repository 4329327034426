@import url('./assets/styles/video-react.css');

@font-face {
  font-family: 'brittany_signatureregular';
  src: url('./assets/fonts/brittanysignature-webfont.woff2') format('woff2'),
       url('./assets/fonts/brittanysignature-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'crimson_proextralight_italic';
  src: url('./assets/fonts/crimsonpro-italic-variablefont_wght-webfont.woff2') format('woff2'),
       url('./assets/fonts/crimsonpro-italic-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'crimson_proextralight';
  src: url('./assets/fonts/crimsonpro-variablefont_wght-webfont.woff2') format('woff2'),
       url('./assets/fonts/crimsonpro-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@keyframes open-left {
  0% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0);
  }
}


@keyframes open-right {
  0% {
    transform: rotateY(-180deg);
  }
  100% {
    transform: rotateY(0);
  }
}

* {
  box-sizing: border-box;
}

body {
  background: #f7f7f7;
  margin: 0;
  font-family: 'crimson_proextralight', 'Times New Roman', Times, serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'brittany_signatureregular', cursive, sans-serif;
  font-weight: normal;
  margin: 0;
}

h2 {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

h3 {
  font-family: 'crimson_proextralight', 'Times New Roman', Times, serif;
  font-size: 1.125rem; /* 18px */
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

p, li {
  font-size: 1.125rem; /* 18px */
  line-height: 1.5;
}

p:last-child {
  margin-bottom: 0;
}

dl {
  margin: 0 0 10px;
  line-height: 1.5;
}

dl dt {
  font-weight: bold;
  display: inline-block;
}

dl dd {
  display: inline;
  margin-left: 0;
}

dl dd:before {
  content: " - ";
}

dl dd:after {
  content: "";
  display: block;
}

a {
  color: #c94369;
  font-weight: bold;
}

ul, ol {
  padding-left: 20px;
}

ul li,
ol li {
  margin-bottom: 0.25rem;
  line-height: 1.2;
}

ul.no-pips {
  list-style: none;
}

.sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.note {
  text-align: center;
  color: #ed7d31;
}

.modal-enter {
  opacity: 0;
  transform: scale(0.4);
}
.modal-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.modal-exit {
  opacity: 1;
}
.modal-exit-active {
  position: absolute;
  opacity: 0;
  transform: scale(0.4);
  transition: opacity 300ms, transform 300ms;
}
